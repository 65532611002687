import React, { Component } from 'react'
import { connect } from 'react-redux'

import Header from './header'
import Navbar from './navbar'
import RouteMain from '../../routes/route.main'
import { appAction } from '../../redux/action/applications'

class MainTemplate extends Component {
  componentDidMount(){
    setTimeout(() => {
      this.props.loadApps()
    }, 100)
  }
  render() {
    const { authen, apps } = this.props
    const activeApps = apps.data && (apps.data).filter((app) => app.active === true)
    
    return (
      <div className='mainContainer'>
        <Header />
        <div id='mainContent' className='mainContent'>
          <div>
            <Navbar role={authen.role} activeApps={activeApps} userId={authen.id}/>
          </div>
          <div className='mainContent-showInfo'>
            <RouteMain />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authen: state.authen,
  apps: state.applications
})

const mapDispatchToProps = dispatch => {
  return {
    loadApps: () => {
      dispatch(appAction.getAllApplication())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainTemplate)