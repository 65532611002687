import React, {Component} from 'react'
import LoginPage from '../../pages/login'


export default class LoginTemplate extends Component{
  render(){ 
    return(
      <LoginPage/>
    )
  }
}