import React from 'react'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import DatePicker from 'react-datepicker'
import ButtonDate from './ButtonDate'

export const renderButtonDate = ({ input, placeholder, label, icon, type, meta: { touched, error, warning } }) => {
  let { name, onChange, value } = { ...input }
  return (
    <div className='dt-inputForm-block'>
      <div className='dt-inputText'>{label}</div>
      <div className={icon}>
        <DatePicker
          customInput={<ButtonDate />}
          name={name}
          dateFormat='dd/MM/YYYY'
          selected={value ? moment(value).toDate() : null}
          // minDate={null}
          // maxDate={moment(new Date()).add(0,'days').toDate()}
          onChange={onChange}
          peekNextMonth
          showMonthDropdown
          showYearDropdown 
          dropdownMode="select"/>
      </div>
      {touched ? ((error && <span className='dt-inputMessage --show'>{error}</span>) || (warning && <span className='dt-inputMessage --show'>{warning}</span>)) : <span className='dt-inputMessage' />}
    </div>
  )
}