import { call } from './setting'

export default {
  authenticate: async (data) => {
    try {
      return await call().then((send) => send.post('/authen/login/', data))
      .catch((err) => {
        return err.response
      })

    } catch (err) {
      console.log('AUTHENTICATE_SERVICE', err)
    }
    
  }
}
