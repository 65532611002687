import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ButtonDate extends Component {

  render() {
    return (
      <>
        <i className="material-icons">date_range</i>
        <button
          type='button'
          className='dt-btn'
          onClick={this.props.onClick}>
          {this.props.value}
        </button>
      </>
    )
  }
}

ButtonDate.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string
}
