import { MODALCOMFIRM } from '../../constants/actionType'

const modalConfirm = {
  message: '',
  id: '',
  open: false
}

export default function modalConfirmReducer(state = modalConfirm, action) {
  switch (action.type) {
    case MODALCOMFIRM.SHOW:
      return {
        ...state,
        message: action.payload.message,
        id:action.payload.id,
        open: true
      }
    case MODALCOMFIRM.HIDE:
      return {
        message: '',
        id: '',
        open: false
      }
    default:
      return state
  }
}