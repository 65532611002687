import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const PrivateUser = ({ component: Component, ...rest }) => {
  return (<Route {...rest} render={props => {
    // console.log(rest)
    return (
      rest.authen.id === rest.computedMatch.params.id ? (
        <Component {...props} />
      ) : (<Redirect to={{
        pathname: process.env.PUBLIC_URL + '/UserInfoPage/' + rest.authen.id
      }} />
        )
    )
  }} />
  )
}

const mapStateToProps = state => (
  { authen: state.authen }
)

export default connect(mapStateToProps)(PrivateUser)