import React from 'react'

export const renderField_w25 = ({ input, placeholder, label, icon, type, meta: { touched, error, warning } }) => (
  <div className='dt-inputForm-block dt-inputForm-block-fix-width-25'>
    <div className='dt-inputText'>{label}</div>
    <div className={icon}>
      <input {...input} placeholder={placeholder} type={type} className='dt-input dt-input-fix' />
    </div>
    {touched ? ((error && <span className='dt-inputMessage --show'>{error}</span>) || (warning && <span className='dt-inputMessage --show'>{warning}</span>)) : <span className='dt-inputMessage' />}
  </div>
)
