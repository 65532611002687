import { APPLICATION } from '../../constants/actionType'

const application = {}

export default function applicationReducer(state = application, action) {
  switch (action.type) {
    case APPLICATION.GET_DATA:
      return action.payload
    case APPLICATION.CLEAR_DATA:
      return {}
    default:
      return state
  }
}
