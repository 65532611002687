import { call } from './setting'

export default {
  getHospital: async () => {
    try {
      return await call().then((send) => send.get('/hospital/'))
        .catch((err) => {
          return err.response
        })

    } catch (err) {
      console.log('GETHOSPITAL_SERVICE', err)
    }
  },
  editHospital: async (data) => {
    try {
      return await call().then((send) => send.put(`/hospital/${data.id}`, data))
        .catch((err) => {
          return err.response
        })

    } catch (err) {
      console.log('EDITHOSPITAL_SERVICE', err)
    }
  },
  getHospitalByCode: async (hcode) => {
    try {
      // console.log('getHospitalByCode', hcode)
      return await call().then((send) => send.get(`/hospital/hcode/${hcode}`))
        .catch((err) => {
          return err.response
        })

    } catch (err) {
      console.log('GETHOSPITALBYCODE_SERVICE', err)
    }
  },
  checkLicense: async (license_key) => {
    try {
      // console.log('sasdasdasd',license_key)
      return await call().then((send) => send.post(`hospital/license/`, { license_key: license_key }))
        .catch((err) => {
          return err.response
        })

    } catch (err) {
      console.log('CHECKLISENSE_SERVICE', err)
    }
  }
}
