import React from 'react'
import { NavLink } from 'react-router-dom'

const TableRow = (props) => {
  return (
    <tr>
      <td>{props.code}</td>
      <td>{props.name}</td>
      <td>{`${window.location.origin}/`}{props.url}</td>
      <td><div className={`circle-active ${props.active ? '--active' : '--notactive'}`}></div></td>
      <td className='dt-tableViewList-action'>
        <NavLink to={`${process.env.PUBLIC_URL}/EditApplicationPage/${props.id}`} className='dt-btn --iconOnly --bg-primary'><i className="material-icons">edit</i></NavLink>
      </td>
    </tr>
  )
}

export default TableRow
