import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'

import { modalConfirmAction } from '../../redux/action/modalConfirm'

class Confirm extends Component {
  render() {
    const { modalConfirm: { open, message, id } } = this.props;
    return (
      <div>
        <Modal open={open} onClose={this.props.hide} closeIconSize={18} center classNames={{ modal: 'customReactResponsiveModal' }}>
          <div className='modalHead'>Confirm</div>
          <div className='modalContent'>
            <div className='modalContent-title'>{message}</div>
          </div>
          <div className='modalFooter'>
            <div className='modalFooter-center'>
              <button className="dt-btn --bg-success" onClick={() => { this.props.todo(id); this.props.hide() }}>
                Yes
              </button>
              <button className="dt-btn" onClick={this.props.hide}>
                No
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  modalConfirm: state.modalConfirm
})

const mapDispatchToProps = dispatch => {
  return {
    hide: () => {
      dispatch(modalConfirmAction.hideConfirmmodal())
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Confirm)