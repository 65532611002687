import { HOSPITAL } from '../../constants/actionType'

const hospital = {}

export default function hospitalReducer(state = hospital, action) {
  switch (action.type) {
    case HOSPITAL.GET_DATA:
      return action.payload
    case HOSPITAL.CLEAR_DATA:
      return {}
    default:
      return state
  }
}
