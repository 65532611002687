import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const PrivateRole = ({ component: Component, ...rest }) => {
  return (<Route {...rest} render={props => {
    return (
      rest.authen.role === 1 ? (
        <Component {...props} />
      ) : (<Redirect to={{
        pathname: process.env.PUBLIC_URL + '/'
      }} />
        )
    )
  }} />
  )
}

const mapStateToProps = state => (
  { authen: state.authen }
)

export default connect(mapStateToProps)(PrivateRole)