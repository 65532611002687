import Authenticate from './authenticate'
import Users from './users'
import Hospital from './hospital'
import Applications from './applications'
import AccessToken from './accesstoken'

export const Service = {
  Authenticate,
  Users,
  Hospital,
  Applications,
  AccessToken
}