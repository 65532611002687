import * as type from '../../constants/actionType'
import { Service } from '../../service'
import { modalAlertAction } from './modalAlert'
import { logoutAction } from './logout'
import { NotificationManager } from 'react-notifications'
import { capitalFirstLetter } from '../../constants/textFunc'

const getUsers = (data) => ({
  type: type.USERS.GET_DATA,
  payload: data
})

const loadUsers = () => ({
  type: type.USERS.LOAD_DATA
})

const getOneUser = (data) => ({
  type: type.USER.GET_DATA,
  payload: data
})

const clearOneUser = () => ({
  type: type.USER.CLEAR_DATA,
})


export const getAllUser = () => async (dispatch) => {
  try {
    const response = await Service.Users.getAllUser()
    if (response.status === 200) {
      dispatch(loadUsers())
      dispatch(getUsers(response.data))
    } else if (response.status === 401) {
      dispatch(logoutAction.logout())
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter('Please login')))
    } else {
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter(response.data.errors.message)))
    }
  } catch (err) {
    console.log('ACTION_GETALLUSERS', err)
    dispatch(modalAlertAction.showAlertmodal('Internal server error'))
  }
}

export const getUserById = (userId) => async (dispatch) => {
  try {
    const response = await Service.Users.getUserById(userId)
    if (response.status === 200) {
      dispatch(getOneUser(response.data))
    } else if (response.status === 401) {
      dispatch(logoutAction.logout())
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter('Please login')))
    } else {
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter(response.data.errors.message)))
    }
  } catch (err) {
    console.log('ACTION_GETUSERBYID', err)
    dispatch(modalAlertAction.showAlertmodal('Internal server error'))
  }
}

export const addUser = (data) => async (dispatch) => {
  try {
    const response = await Service.Users.addUser(data)
    if (response.status === 200) {
      // dispatch(modalAlertAction.showAlertmodal('Success'))
      NotificationManager.success('Successful', 'Add', 3000)
      dispatch(usersAction.getAllUser())
    } else if (response.status === 401) {
      dispatch(logoutAction.logout())
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter('Please login')))
    } else if (response.status === 422) {
      NotificationManager.warning('Fail', 'Add', 3000)
    } else {
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter(response.data.errors.message)))
    }
  } catch (err) {
    console.log('ACTION_ADDUSER', err)
    dispatch(modalAlertAction.showAlertmodal('Internal server error'))
  }
}

export const editUser = (userId, data) => async (dispatch) => {
  try {
    const response = await Service.Users.editUser(userId, data)
    if (response.status === 200) {
      // dispatch(modalAlertAction.showAlertmodal('Success'))
      NotificationManager.success('Successful', 'Edit', 3000)
      dispatch(usersAction.getAllUser())
      dispatch(clearOneUser())
    } else if (response.status === 401) {
      dispatch(logoutAction.logout())
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter('Please login')))
    } else if (response.status === 422) {
      NotificationManager.warning('Fail', 'Edit', 3000);
    } else {
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter(response.data.errors.message)))
    }
  } catch (err) {
    console.log('ACTION_EDITUSER', err)
    dispatch(modalAlertAction.showAlertmodal('Internal server error'))
  }
}

export const resetUser = () => async (dispatch) => {
  try {
    dispatch(clearOneUser())
  } catch (err) {
    console.log('ACTION_RESETUSER', err)
  }
}

export const usersAction = {
  getAllUser,
  getUserById,
  addUser,
  editUser,
  resetUser
}

