import * as type from '../../constants/actionType'
import {Service} from '../../service'
import {modalAlertAction} from './modalAlert'
import {logoutAction} from './logout'
import {modalConfirmAction} from './modalConfirm'
import {NotificationManager} from 'react-notifications'
import {capitalFirstLetter} from '../../constants/textFunc'
 
const getAccessToken = (data) => ({
  type: type.ACCESSTOKEN.GET_DATA,
  payload: data
})

const loadAccessToken = () => ({
  type: type.ACCESSTOKEN.LOAD_DATA
})

export const getAllAccesstoken = () => async (dispatch) =>{
  try{
    const response = await Service.AccessToken.getAllAccesstoken()
    if(response.status === 200){
      dispatch(loadAccessToken())
      dispatch(getAccessToken(response.data))
    }else if(response.status === 401){
      dispatch(logoutAction.logout())
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter('Please login')))
    }else{
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter(response.data.errors.message)))
    }
  }catch(err){
    console.log('ACTION_GETALLACCESSTOKEN',err)
    dispatch(modalAlertAction.showAlertmodal('Internal server error'))
  }
}

export const addAccessToken = (data) => async (dispatch) => {
  try{
    const response = await Service.AccessToken.addAccessToken(data)
    if(response.status === 200){
      dispatch(getAllAccesstoken())
      // dispatch(modalAlertAction.showAlertmodal('Success'))
      NotificationManager.success('Successful', 'Add',3000)
    }else if(response.status === 401){
      dispatch(logoutAction.logout())
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter('Please login')))
    }else if(response.status === 422){
      NotificationManager.warning('Fail', 'Add', 3000);
    }else{
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter(response.data.errors.message)))
    }
  }catch(err){
    console.log('ACTION_ADDACCESSTOKEN',err)
    dispatch(modalAlertAction.showAlertmodal('Internal server error'))
  }
}

export const delAccessToken = (appTokenId) => async (dispatch) => {
  try{
    const response = await Service.AccessToken.delAccessToken(appTokenId)
    if(response.status === 200){
      dispatch(modalConfirmAction.hideConfirmmodal())
      dispatch(getAllAccesstoken())
      // dispatch(modalAlertAction.showAlertmodal('Success'))
      NotificationManager.success('Successful', 'Delete',3000)
    }else if(response.status === 401){
      dispatch(logoutAction.logout())
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter('Please login')))
    }else if(response.status === 422){
      NotificationManager.warning('Fail', 'Delete', 3000);
    }else{
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter(response.data.errors.message)))
    }
  }catch(err){
    console.log('ACTION_DELACCESSTOKEN',err)
    dispatch(modalAlertAction.showAlertmodal('Internal server error'))
  }
}

export const accessTokenAction = {
  getAllAccesstoken,
  addAccessToken,
  delAccessToken
} 