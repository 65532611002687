import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reset } from 'redux-form'

import HosInfoForm from './form'
import { hospitalAction } from '../../redux/action/hospital'
import { CircleLoading } from '../../components/loading/circleloading'
import { Service } from '../../service'

class siteManagePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hname: '',
      errorLicense: false,
      licenseExpDate: '',
    }
    this.timer = null;
  }
  componentDidMount() {
    setTimeout(() => {
      this.props.getHosData()
    }, 100)
  }
  componentWillReceiveProps(nextProps) {
    const { hospital } = nextProps
    // console.log('hospital', hospital)
    this.checkLicense(hospital.license_key)
    this.setState({
      ...this.state,
      hname: hospital.name
    })
  }
  submit = value => {
    if (this.state.hname) {
      value.name = this.state.hname
    }
    // console.log('test',value)
    this.props.editHosData(value)
    window.location.reload()
  }
  cancle = () => {
    this.props.resetForm()
  }
  checkHCode = async (e) => {
    const response = await Service.Hospital.getHospitalByCode(e.target.value)
    response.data.hname ? this.setState({ hname: response.data.hname }) : this.setState({ hname: '' })
  }
  delayCheckHcode = (value) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      await this.checkHCode(value)
    }, 1000);
  }
  checkLicense = async (value) => {
    const response = await Service.Hospital.checkLicense(value)
    // console.log('asdasdawwwwwwwwd', response)
    if (response.data.error || response.data.errors) {
      // console.log('asdasdawwwwwwwwd', response.data)
      this.setState({ errorLicense: true, licenseExpDate: '' })
    } else {
      this.setState({ errorLicense: false, licenseExpDate: response.data.exp })
    }
  }

  render() {
    const { hospital } = this.props
    const { hname, errorLicense, licenseExpDate } = this.state

    // console.log('ssdsfsdfsdfsfsdf', this.state , errorLicense && !licenseExpDate)

    if (Object.keys(hospital).length === 0) {
      return <CircleLoading />
    } else {
      return (
        <div>
          <div className="showInfoHead">
            <div className="showInfoHead-title">Site Profile</div>
            <div className="showInfoHead-contentRight" />
          </div>
          <div className="showInfoContent">
            {
              <HosInfoForm
                hospital={hospital}
                onSubmit={this.submit}
                hname={hname}
                errorLicense={errorLicense}
                cancle={this.cancle}
                checkHCode={this.delayCheckHcode}
                checkLicense={this.checkLicense}
                checkDateExp={this.checkDateExp}
                licenseExpDate={licenseExpDate}
              />
            }
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  hospital: state.hospital
})

const mapDispatchToProps = dispatch => {
  return {
    getHosData: () => {
      dispatch(hospitalAction.getHospital())
    },
    editHosData: (data) => {
      dispatch(hospitalAction.editHospiatl(data))
    },
    resetForm: () => {
      dispatch(reset('HosInfoForm'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(siteManagePage)
