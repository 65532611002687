import React from 'react'

export const renderField = ({ input, placeholder, label, icon, type, meta: { touched, error, warning } }) => {
  return (
    <div className='dt-inputForm-block'>
      <div className='dt-inputText'>{label}</div>
      <div className={icon}>
        <input {...input} placeholder={placeholder} type={type} className='dt-input dt-input-w100' />
      </div>
      {touched ? ((error && <span className='dt-inputMessage --show'>{error}</span>) || (warning && <span className='dt-inputMessage --show'>{warning}</span>)) : <span className='dt-inputMessage' />}
    </div>
  )
}
