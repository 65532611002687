import React from 'react'

export const renderFieldPassword = ({ input, placeholder, label, icon, type, meta: { touched, error, warning,submitFailed } }) => (
  <div className='dt-inputForm-block'>
    <div className='dt-inputText'>{label}</div>
    <div className={icon}>
      <input {...input} placeholder={placeholder} type={type} className='dt-input dt-input-w99' />
    </div>
    <div className={submitFailed ? 'dt-inputDescription blink' : 'dt-inputDescription'}>
      Make sure it's
      {touched
        ? (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-._]).{8,}$/g.test(input.value)
          ? <span className='message-success'> least 8 character</span>
          : <span className='message-error'> least 8 character</span>)
        : <span> least 8 character</span>
      } including
      {touched
        ? (/[A-Z]/g.test(input.value)
          ? <span className='message-success'> upper case letter (A-Z)</span>
          : <span className='message-error'> upper case letter (A-Z)</span>)
        : <span> upper case letter (A-Z)</span>
      } ,
      {touched
        ? (/[a-z]/g.test(input.value)
          ? <span className='message-success'> lower case letter (a-z)</span>
          : <span className='message-error'> lower case letter (a-z)</span>)
        : <span> lower case letter (a-z)</span>
      } ,
      {touched
        ? (/[0-9]/g.test(input.value)
          ? <span className='message-success'> number (0-9)</span>
          : <span className='message-error'> number (0-9)</span>)
        : <span> lower case letter (a-z)</span>
      } and
      {touched
        ? (/[#?!@$%^&*-._]/g.test(input.value)
          ? <span className='message-success'> special character (#?!@$%^&*-._)</span>
          : <span className='message-error'> special character (#?!@$%^&*-._)</span>)
        : <span> special character (#?!@$%^&*-._)</span>
      }
    </div>
    {touched ? ((error === 'Required' && <span className='dt-inputMessage --show'>{error}</span>) || (warning && <span className='dt-inputMessage --show'>{warning}</span>)) : <span className='dt-inputMessage' />}
  </div>
)
