import { MODALALERT } from '../../constants/actionType'

const modalAlert = {
  message: '',
  open: false
}

export default function modalAlertReducer(state = modalAlert, action) {
  switch (action.type) {
    case MODALALERT.SHOW:
      return {
        ...state,
        message: action.payload,
        open: true
      }
    case MODALALERT.HIDE:
      return {
        message: '',
        open: false
      }
    default:
      return state
  }
}