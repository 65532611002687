import * as type from '../../constants/actionType'
import { Service } from '../../service'
import { modalAlertAction } from './modalAlert'
import { logoutAction } from './logout'
import { NotificationManager } from 'react-notifications'
import {capitalFirstLetter} from '../../constants/textFunc'

const getHosData = (data) => ({
  type: type.HOSPITAL.GET_DATA,
  payload: data
})

const clearData = () => ({
  type: type.HOSPITAL.CLEAR_DATA
})


export const getHospital = () => async (dispatch) => {
  try {
    const response = await Service.Hospital.getHospital()
    if (response.status === 200) {
      dispatch(getHosData(response.data[0]))
    } else if (response.status === 401) {
      dispatch(logoutAction.logout())
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter('Please login')))
    } else {
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter(response.data.errors.message)))
    }
  } catch (err) {
    console.log('ACTION_GETHOSPITAL', err)
    dispatch(modalAlertAction.showAlertmodal('Internal server error'))
  }
}

export const editHospiatl = (data) => async (dispatch) => {
  try {
    const response = await Service.Hospital.editHospital(data)
    if (response.status === 200) {
      // dispatch(modalAlertAction.showAlertmodal('Success'))
      NotificationManager.success('Successful', 'Edit', 3000)
      dispatch(clearData())
      dispatch(hospitalAction.getHospital())
    } else if (response.status === 401) {
      dispatch(logoutAction.logout())
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter('Please login')))
    } else if (response.status === 422) {
      NotificationManager.warning('Fail', 'Edit', 3000);
    } else {
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter(response.data.errors.message)))
    }
  } catch (err) {
    console.log('ACTION_EDITHOSPITAL', err)
    dispatch(modalAlertAction.showAlertmodal('Internal server error'))
  }
}
export const hospitalAction = {
  getHospital,
  editHospiatl
}

