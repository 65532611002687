import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import LoginForm from './form'
import {authenAction} from '../../redux/action/authenticate'
import styles from '../../assets/scss/pages/login.scss'

class LoginPage extends Component {
  submit = value => {
    this.props.authenticate(value)
  }
  render() {
    const {authen: {isLogin} } = this.props
    return (
      // !isLogin 
      !isLogin  ? <div className={styles['loginContainer']}>
      <div className={`${styles['loginContent']} ${styles['loginContent-fix']}`}>
        <div className={styles['loginContent-title']}>
          <h2>Smart Hospital Web Admin</h2>
        </div>
        {/* <div className={`${styles['loginContent-message']} ${styles[`${this.state.classShow}`]}`}></div> */}
        <LoginForm onSubmit={this.submit}/>
      </div>
    </div> :  <Redirect to={{ pathname: `${process.env.PUBLIC_URL}/` }} />
    )
  }
}

const mapStateToProps = state => ({
  authen: state.authen
})

const mapDispatchToProps = dispatch => {
  return {
    authenticate: data => {
      dispatch(authenAction.authenticate(data))
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(LoginPage)