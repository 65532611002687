//login
export const AUTHENTICATE = {
  SET_DATA: 'AUTHENTICATE_SET_DATA',
  CLEAR_DATA: 'AUTHENTICATE_CLEAR_DATA'
}
Object.freeze(AUTHENTICATE)

// all users
export const USERS = {
  GET_DATA: 'USERS_GET_DATA',
  LOAD_DATA: 'USERS_LOAD_DATA'
}
Object.freeze(USERS)

// one user
export const USER = {
  GET_DATA: 'USER_GET_DATA',
  CLEAR_DATA: 'USER_CLEAR_DATA'
}
Object.freeze(USER)

// logout
export const LOGOUT = {
  LOGOUT: 'USER_LOGOUT'
}
Object.freeze(LOGOUT)

export const HOSPITAL = {
  GET_DATA: 'HOSPITAL_GET_DATA',
  CLEAR_DATA: 'HOSPITAL_CLEAR_DATA'
}
Object.freeze(HOSPITAL)

//one app 
export const APPLICATION = {
  GET_DATA: 'APPLICATION_GET_DATA',
  CLEAR_DATA: 'APPLICATION_CLEAR_DATA'
}
Object.freeze(APPLICATION)

//all app 
export const APPLICATIONS = {
  GET_DATA: 'APPLICATIONS_GET_DATA',
  LOAD_DATA: 'APPLICATIONS_LOAD_DATA'
}
Object.freeze(APPLICATIONS)

export const ACCESSTOKEN = {
  GET_DATA: 'ACCESSTOKEN_GET_DATA',
  LOAD_DATA: 'ACCESSTOKEN_LOAD_DATA'
}
Object.freeze(ACCESSTOKEN)

// modal alert
export const MODALALERT = {
  SHOW: 'MODALALERT_SHOW',
  HIDE: 'MODALALERT_HIDE'
}
Object.freeze(MODALALERT)

// modal confirm
export const MODALCOMFIRM = {
  SHOW: 'MODALCOMFIRM_SHOW',
  HIDE: 'MODALCOMFIRM_HIDE'
}
Object.freeze(MODALCOMFIRM)
