import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import EditUserForm from './form'
import { usersAction } from '../../redux/action/users'
import { modalAlertAction } from '../../redux/action/modalAlert'
import {capitalFirstLetter} from '../../constants/textFunc'
import { CircleLoading } from '../../components/loading/circleloading'

class EditUserPage extends Component {
  componentWillMount() {
    this.props.resetUser()
  }
  componentDidMount() {
    const { match: { params } } = this.props
    this.props.getUserById(params.id)
  }
  submit = value => {
    console.log(value)
    if(value.password !== value.confirmpassword){
      // alert('confirmpassword not match password')
      this.props.showAlert(capitalFirstLetter('confirmpassword not match password'))
      return false
    } 
    const {match:{params}} = this.props
    const data = {
      full_name: value.full_name,
      username: value.username,
      current_password: value.current_password,
      password: value.password,
      active:  value.active ? value.active : false,
      roleId: parseInt(value.roleId),
      birthdate: String(moment(value.birthdate).format('YYYY-MM-DD')),
      cid: value.cid
    }

    this.props.editUser(params.id,data)
    this.props.history.push(`${process.env.PUBLIC_URL}/userManagePage`)

  }
  render() {
    const { user } = this.props

    if (Object.keys(user).length === 0) {
      return <CircleLoading/>
    } else {
      return (
        <div>
          <div className="showInfoHead">
            <div className="showInfoHead-title">Edit User</div>
            <div className="showInfoHead-contentRight" />
          </div>
          <div className="showInfoContent">
            <EditUserForm onSubmit={this.submit}/>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => {
  return {
    getUserById: (userId) => {
      dispatch(usersAction.getUserById(userId))
    },
    editUser: (userId,data) => {
      dispatch(usersAction.editUser(userId,data))
    },
    resetUser: () => {
      dispatch(usersAction.resetUser())
    },
    showAlert: (message) => {
      dispatch(modalAlertAction.showAlertmodal(message))
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(EditUserPage)
