const required = value => value ? undefined : 'Required'

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength13 = maxLength(13)

const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined

const minValue = min => value =>
  value && value.length < min ? `Must be at least ${min}` : undefined
const minValue8 = minValue(8)
const minValue4 = minValue(4)
const minValue2 = minValue(2)
const minValue13 = minValue(13)

const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address' : undefined

const tooOld = value =>
  value && value > 65 ? 'You might be too old for this' : undefined

const aol = value =>
  value && /.+@aol\.com/.test(value)
    ? 'Really? You still use AOL for your email?' : undefined

const uppercaseLetter = value => /[A-Z]/g.test(value) ? undefined : 'Need upper case letter'
const lowercaseLetter = value => /[a-z]/g.test(value) ? undefined : 'Need lower case letter'
const ditgi = value => /[0-9]/g.test(value) ? undefined : 'Need number'
const specialCharacter = value => /[#?!@$%^&*-._]/g.test(value) ? undefined : 'Need special character'
const passwordMustContain = value => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-._]).{8,}$/g.test(value) ? undefined : 'Password must be at least 8'

const changePass = value => {
  if (value) {
    return !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-._]).{8,}$/g.test(value) ? 'Password incorrect' : undefined 
  } else {
    return undefined
  }
}

const confirmPass = (value, allValue) => {
  return value === allValue.password ? undefined : `Password don't match`
}

export const validate = {
  required,
  maxLength,
  maxLength13,
  number,
  minValue8,
  email,
  tooOld,
  aol,
  minValue2,
  minValue4,
  minValue13,
  uppercaseLetter,
  lowercaseLetter,
  ditgi,
  specialCharacter,
  passwordMustContain,
  confirmPass,
  changePass
}
