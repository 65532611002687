import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import TableTbody from './table-tbody'
import SearchBox from './searchBox'
import { appAction } from '../../redux/action/applications'
import { CircleLoading } from '../../components/loading/circleloading'

class appManagePage extends Component {
  state = {
    searchfield: '',
  }
  componentDidMount() {
    this.props.loadApps()
  }
  onSearchChange = (event) => {
    this.setState({ searchfield: event.target.value })
  }
  render() {
    const { searchfield } = this.state
    const { applications } = this.props
    const filterApplications = applications.data && (applications.data).filter((app) => {
      return app.name.toLowerCase().includes(searchfield.toLowerCase()) || app.code.toLowerCase().includes(searchfield.toLowerCase())
    })
    if (applications.isLoading) {
      return <CircleLoading />
    } else {
      return (
        <div>
          <div className="showInfoHead">
            <div className="showInfoHead-title">
              App management
            </div>
            <div className='showInfoHead-title showButtonHead'>
              <NavLink to={`${process.env.PUBLIC_URL}/AddApplicationPage`} className='dt-btn --iconOnly --bg-success'><i className="material-icons">add</i></NavLink>
            </div>
            <SearchBox searchChange={this.onSearchChange} />
          </div>
          <div className="showInfoContent">
            {
              (applications.data).length !== 0
                ? (
                  <table className="dt-tableViewList --widthMax">
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Application</th>
                        <th>URL</th>
                        <th className='fixWidth'>Active</th>
                        <th></th>
                      </tr>
                    </thead>
                    <TableTbody apps={filterApplications} />
                  </table>
                )
                : (
                  <div>No data</div>
                )
            }
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  applications: state.applications
})

const mapDispatchToProps = dispatch => {
  return {
    loadApps: () => {
      dispatch(appAction.getAllApplication())
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(appManagePage)
