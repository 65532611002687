import React, { Component } from 'react'
import moment from 'moment'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import { validate } from '../../../constants/validateform'
import { renderField_w25 } from '../../../components/input-form/renderField_w25'
// import { renderField_w75 } from '../../../components/input-form/renderField_w75'
import { renderField_w100 } from '../../../components/input-form/renderField_w100'
import { renderHiddenField } from '../../../components/input-form/renderHiddenField'
import { renderCheckLicense } from '../../../components/input-form/renderCheckLicens'

class HosInfoForm extends Component {
  state = {
    hname: null,
    errorLicense: false,
    licenseExpDate: ''
  }
  componentWillReceiveProps(nextProps) {
    // console.log('nextProps',nextProps)
    this.setState({ hname: nextProps.hname, errorLicense: nextProps.errorLicense, licenseExpDate: nextProps.licenseExpDate })
  }
  checkDateExp = () => {
    const { licenseExpDate } = this.state
    // console.log('licenseExpDate',licenseExpDate)
    if (!licenseExpDate) return false

    const expiration = licenseExpDate && moment(licenseExpDate, 'YYYY-MM-DD').format("YYYY-MM-DD")
    const current_date = licenseExpDate && moment().format("YYYY-MM-DD")
    const days = expiration && current_date && moment(expiration).diff(current_date, 'days')
    return days
  }
  checkTimeExp = () => {
    const { licenseExpDate } = this.state
    if (!licenseExpDate) return false

    const now = moment();
    const expiration = licenseExpDate && moment(licenseExpDate)

    // get the difference between the moments
    const diff = expiration.diff(now)
    //express as a duration
    const diffDuration = moment.duration(diff)

    const days = diffDuration.days()
    const hours = diffDuration.hours()
    const minutes = diffDuration.minutes()

    return { days, hours, minutes }
  }
  render() {
    const { handleSubmit, cancle, checkHCode, checkLicense } = this.props
    const { hname, errorLicense, licenseExpDate } = this.state

    // console.log('HosInfoForm', this.state)
    return (
      <form className='basicForm' onSubmit={handleSubmit}>
        <div className='dt-inputForm --oneBlock'>
          <div className='formTitle'>
            <h2>Hospital Information</h2>
          </div>
          <Field
            name='id'
            // placeholder='current password'
            type='hidden'
            className='dt-input'
            component={renderHiddenField}
            icon='dt-inputField'
          // validate={[validate.required, validate.minValue8]}
          />
          <Field
            name='code'
            // placeholder='current password'
            type='text'
            className='dt-input'
            component={renderField_w25}
            icon='dt-inputField'
            validate={[validate.required]}
            label='Hospital code'
            onChange={checkHCode}
          />
          <div className='dt-inputForm-block dt-inputForm-block-fix-width-75'>
            <div className='dt-inputText'>Hospital Name</div>
            <div className=''>
              <input disabled name='name' type='text' className='dt-input dt-input-fix' value={hname ? hname : ''} />
            </div>
          </div>
          {/* <Field
            name='name'
            // placeholder='newpassword'
            type='text'
            className='dt-input'
            autoComplete='Off'
            component={renderField_w75}
            icon='dt-inputField'
            // validate={[validate.required, validate.minValue8]}
            label='Hopital Name'
            hname={hname}
          /> */}
          <Field
            name='contact'
            // placeholder='confirm password'
            type='text'
            className='dt-input'
            autoComplete='Off'
            component={renderField_w100}
            icon='dt-inputField'
            validate={[validate.required]}
            label='Contact'
          />
          <Field
            name='license_key'
            label='License Key'
            // placeholder='current password'
            type='text'
            className='dt-input'
            component={renderCheckLicense}
            icon='dt-inputField'
            onChange={e => checkLicense(e.target.value)}
            // validate={[validate.required]}
            errorLicense={errorLicense}
          />
          {
            errorLicense && !licenseExpDate
              ? (<div className='licenseBar expire'>Your license key not activate.</div>)
              : (
                this.checkDateExp() < 180
                  ? (this.checkDateExp() <= 0
                    ? (
                      this.checkTimeExp().days <= 0
                        && this.checkTimeExp().hours <= 0
                        && this.checkTimeExp().minutes <= 0
                        ? (<div className='licenseBar expire'>Your license key has expired.</div>)
                        : (<div className='licenseBar warning'>Your license key has expired on {moment(licenseExpDate).format('YYYY-MM-DD HH:mm')}</div>)
                    )
                    : (<div className='licenseBar warning'>Your license key has expired on {moment(licenseExpDate).format('YYYY-MM-DD')}</div>))
                  : (<div className='licenseBar active'>Your license key is active.</div>)

              )
          }
          {/* {this.checkDateExp() < 180
            ? (this.checkDateExp() <= 0
              ? (<div className='licenseBar expire'>Your license key has expired.</div>)
              : (<div className='licenseBar warning'>Your license key has expired on {moment(licenseExpDate).format('YYYY-MM-DD')}</div>))
            : (<div className='licenseBar active'>Your license key is active.</div>)
          } */}
        </div>
        <div className='btnControl'>
          <div className='btnControl-center'>
            <button
              className='dt-btn --bg-success'
              type='submit'
              disabled={errorLicense && licenseExpDate}
            >
              save
        </button>
            <button type='button' className='dt-btn' onClick={cancle}>cancel</button>
          </div>
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: {
      id: ownProps.hospital._id,
      code: ownProps.hospital.code,
      contact: ownProps.hospital.contact,
      license_key: ownProps.hospital.license_key
    }
  }
}

HosInfoForm = reduxForm({
  form: 'HosInfoForm'
})(HosInfoForm)

export default connect(mapStateToProps)(HosInfoForm)
