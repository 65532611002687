import React from 'react'
import { NavLink } from 'react-router-dom'

const TableRow = (props) => {
  return (
    <tr>
      <td>{props.name}</td>
      <td>{props.username}</td>
      <td>{props.userrole._id === 1 ? 'Admin' : 'User'}</td>
      <td><div className={`circle-active ${props.active ? '--active' : '--notactive'}`}></div></td>
      <td className='dt-tableViewList-action'>
        <NavLink to={`${process.env.PUBLIC_URL}/EditUserPage/${props.id}`} id={props.id} className='dt-btn --iconOnly --bg-primary'><i className="material-icons">edit</i></NavLink>
      </td>
    </tr>
  )
}

export default TableRow
