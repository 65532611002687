import React, { Component } from 'react'
import {connect} from 'react-redux'

import AddAppForm from './form'
import { appAction } from '../../redux/action/applications'

class AddApplicationPage extends Component {
  submit = value => {
    // console.log(value)
    const data = {
      code: value.code,
      name: value.name,
      url: value.url,
      active: value.active ? value.active : false
    }

    this.props.addApplication(data)
    this.props.history.push(`${process.env.PUBLIC_URL}/appManagePage`)
  }
  render() {
    return (
      <div>
        <div className="showInfoHead">
          <div className="showInfoHead-title">Add Application</div>
          <div className="showInfoHead-contentRight" />
        </div>
        <div className="showInfoContent">
          <AddAppForm onSubmit={this.submit}/>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addApplication: (data) => {
      dispatch(appAction.addApplication(data))
    }
  }
}

export default connect(null,mapDispatchToProps)(AddApplicationPage)
