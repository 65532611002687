import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reset } from 'redux-form'

import AccessTokenForm from './form'
import TableTbody from './table-tbody'
import { accessTokenAction } from '../../redux/action/accesstoken'
import { appAction } from '../../redux/action/applications'
import Confirm from '../../components/modal/modalConfirm'
import { CircleLoading } from '../../components/loading/circleloading'

class accessTokenPage extends Component {
  state = {
    app: []
  }
  componentDidMount() {
    this.props.loadAccesstoken()
    this.props.loadApps()
  }
  submit = value => {
    const data = {
      name: value.tokenName,
      application: this.state.app,
      active: true
    }
    // console.log(data)
    this.props.addAccessToken(data)
    this.props.resetForm()
    this.setState({ app: [] })
  }
  handleChange = (e, { value }) => {
    this.setState({ app: value })
  }
  render() {
    const { apps } = this.props

    const activeApps = apps.data && (apps.data).filter((app) => app.active === true)

    const mapOption = activeApps && activeApps.map((item) => {
      let rObj = {};
      rObj['key'] = item._id;
      rObj['text'] = item.name;
      rObj['value'] = item._id;
      return rObj
    })

    const { accessToken } = this.props
    if (accessToken.isLoading) {
      return <CircleLoading />
    } else {
      return (
        <div>
          <div className="showInfoHead">
            <div className="showInfoHead-title">
              Access Token Page
        </div>
            <div className="showInfoHead-contentRight">
            </div>
          </div>
          <div className="showInfoContent">
            <AccessTokenForm onSubmit={this.submit} option={mapOption} onChangex={this.handleChange} app={this.state.app} />
            {/* {
              mapOption && <DropdownExampleMultipleSelection option={mapOption} onChangex={this.handleChange} />
            } */}
            {
              (accessToken.data).length !== 0
                ? (
                  <table className="dt-tableViewList --widthMax">
                    <thead>
                      <tr>
                        <th>Access Token Name</th>
                        <th>Access Token</th>
                        <th>Application</th>
                        <th></th>
                      </tr>
                    </thead>
                    <TableTbody accessTokens={accessToken.data} />
                  </table>
                )
                : (
                  <div>No data</div>
                )
            }

            <Confirm todo={(id) => this.props.delAccesstoken(id)} />
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  accessToken: state.accessToken,
  apps: state.applications
})

const mapDispatchToProps = dispatch => {
  return {
    loadAccesstoken: () => {
      dispatch(accessTokenAction.getAllAccesstoken())
    },
    loadApps: () => {
      dispatch(appAction.getAllApplication())
    },
    addAccessToken: (data) => {
      dispatch(accessTokenAction.addAccessToken(data))
    },
    delAccesstoken: (id) => {
      dispatch(accessTokenAction.delAccessToken(id))
    },
    resetForm: () => {
      dispatch(reset('AccessTokenForm'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(accessTokenPage)
