import React, { Component } from 'react'


class userIndexPage extends Component {
  render() {
    return (
      <div>
        <div className="showInfoHead">
          <div className="showInfoHead-title">
            user index page
            </div>
        </div>
        <div className="showInfoContent">
          
        </div>
      </div>
    )
  }
}


export default (userIndexPage)
