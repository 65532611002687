import { call } from './setting'

export default {
  addApplication: async (data) => {
    try {
      return await call().then((send) => send.post('/application/', data))
      .catch((err) => {
        return err.response
      })

    } catch (err) {
      console.log('ADDAPPLICATION_SERVICE', err)
    }
  },
  getAllApplication: async () => {
    try {
      return await call().then((send) => send.get('/application/'))
      .catch((err) => {
        return err.response
      })

    } catch (err) {
      console.log('GETALLAPPLICATION_SERVICE', err)
    }
  },
  getApplicationById: async (appId) => {
    try {
      return await call().then((send) => send.get(`/application/${appId}`))
      .catch((err) => {
        return err.response
      })

    } catch (err) {
      console.log('GETAPPLICATIONBYID_SERVICE', err)
    }
  },
  editApplication: async (appId,data) => {
    try {
      return await call().then((send) => send.put(`/application/${appId}`,data))
      .catch((err) => {
        return err.response
      })

    } catch (err) {
      console.log('EDITAPPLICATION_SERVICE', err)
    }
  }
}
