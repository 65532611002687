import React from 'react'

export const renderDropdown = ({ input, placeholder, label, icon, type, meta: { touched, error, warning } }) => (
  <div className='dt-inputForm-block'>
    <div className='dt-inputText'>{label}</div>
    <div className={icon}>
      {/* <input {...input} placeholder={placeholder} type={type} className='dt-input dt-input-fix'/> */}
      <div className='dt-select dt-select-fix dt-input-w100'>
        <select {...input}>
          <option value=''></option>
          <option value='1'>Admin</option>
          <option value='2'>User</option>
        </select>
      </div>
    </div>
    {touched ? ((error && <span className='dt-inputMessage --show'>{error}</span>) || (warning && <span className='dt-inputMessage --show'>{warning}</span>)) : <span className='dt-inputMessage' />}
  </div>
)
