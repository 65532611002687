import { USER } from '../../constants/actionType'

const user = {}

export default function userReducer(state = user, action) {
  switch (action.type) {
    case USER.GET_DATA:
      return action.payload
    case USER.CLEAR_DATA:
      return {}
    default:
      return state
  }
}
