import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { NotificationManager } from 'react-notifications'

import { modalConfirmAction } from '../../redux/action/modalConfirm'
import LabelExampleIcon from '../../components/semantic/labelTag'

class TableRow extends Component {
  state = {
    text: this.props.token,
    copied: false
  }
  copyToken = () => {
    this.setState({copied: true})
    NotificationManager.success(`Copied ${this.state.text} to clipboard successful`, `Copied to clipboard`, 3000)
    setTimeout(() => {
      this.setState({copied: false})
    }, 3000)
  }
  render() {
    const {text, copied} = this.state
    const { name, token, app, id } = this.props
    return (
      <tr>
        <td>{name}</td>
        <td>
          <div className='token-text'>
            <p className={copied ? 'copy-active' : ''}>{token}</p>
            <CopyToClipboard text={text}>
              <button className='dt-btn --iconOnly' onClick={this.copyToken}><i className="material-icons">filter_none</i></button>
            </CopyToClipboard>
          </div>
        </td>
        <td>{
          (app).map((item, key) => {
            return <LabelExampleIcon key={key} tagName={item.name} tagId={item._id} allDataInRow={this.props} />
          })
        }
        </td>
        <td className='dt-tableViewList-action'>
          <button className='dt-btn --iconOnly --bg-error' onClick={() => { this.props.showConfirm(`Are you sure you want to delete?`, id) }}><i className="material-icons">delete</i></button>
        </td>
      </tr>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showConfirm: (message, id) => {
      dispatch(modalConfirmAction.showConfirmmodal(message, id))
    }
  }
}

export default connect(null, mapDispatchToProps)(TableRow)
