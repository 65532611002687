import React from 'react'
import { Field, reduxForm } from 'redux-form'


import styles from '../../../assets/scss/pages/login.scss'
import { renderField } from '../../../components/input-form/renderField'
import { validate } from '../../../constants/validateform'

let LoginForm = props => {
  const { handleSubmit } = props
  return (
    <form onSubmit={handleSubmit}>
      <div className={`dt-inputForm --oneBlock ${styles['loginContent-inputGroup']}`}>
        {/* <div className='dt-inputForm-block'>
          <div className='dt-inputText'>username</div>
          <div className='dt-inputField ico-user'>
            <Field name='username' placeholder='username' component='input' type='text' className='dt-input' />
          </div>
        </div>
        <div className='dt-inputForm-block'>
          <div className='dt-inputText'>password</div>
          <div className='dt-inputField ico-key'>
            <Field name='password' placeholder='password' component='input' type='password' className='dt-input' />
          </div>
        </div> */}
        <Field
          name='username'
          // placeholder='current password'
          type='text'
          className='dt-input'
          component={renderField}
          icon='dt-inputField ico-user'
          validate={[validate.required]}
          label='Username'
        />
        <Field
          name='password'
          // placeholder='current password'
          type='password'
          className='dt-input'
          component={renderField}
          icon='dt-inputField ico-key'
          validate={[validate.required]}
          label='Password'
        />
      </div>
      <div className={styles['loginContent-btnControl']}>
        <button className='dt-btn --bg-primary' type='submit'>Login</button>
      </div>
    </form>
  )
}

LoginForm = reduxForm({
  form: 'LoginForm'
})(LoginForm)

export default LoginForm
