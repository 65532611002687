import { call } from './setting'

export default {
  getAllAccesstoken: async () => {
    try {
      return await call().then((send) => send.get('/access-token/'))
        .catch((err) => {
          return err.response
        })

    } catch (err) {
      console.log('GETALLACCESSTOKEN_SERVICE', err)
    }
  },
  addAccessToken: async (data) => {
    try {
      return await call().then((send) => send.post('/access-token/', data))
        .catch((err) => {
          return err.response
        })

    } catch (err) {
      console.log('ADDACCESSTOKEN_SERVICE', err)
    }
  },
  delAccessToken: async (tokenAppId) => {
    try {
      return await call().then((send) => send.put(`/access-token/${tokenAppId}`))
        .catch((err) => {
          return err.response
        })

    } catch (err) {
      console.log('DELACCESSTOKEN_SERVICE', err)
    }
  }
}
