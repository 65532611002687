import 'semantic-ui-css/semantic.min.css'
import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'

// const options = [
//   { key: 'angular', text: 'Angular', value: 'angular' },
//   { key: 'css', text: 'CSS', value: 'css' },
//   { key: 'design', text: 'Graphic Design', value: 'design' },
//   { key: 'ember', text: 'Ember', value: 'ember' },
//   { key: 'html', text: 'HTML', value: 'html' },
//   { key: 'ia', text: 'Information Architecture', value: 'ia' },
//   { key: 'javascript', text: 'Javascript', value: 'javascript' },
//   { key: 'mech', text: 'Mechanical Engineering', value: 'mech' },
//   { key: 'meteor', text: 'Meteor', value: 'meteor' },
//   { key: 'node', text: 'NodeJS', value: 'node' },
//   { key: 'plumbing', text: 'Plumbing', value: 'plumbing' },
//   { key: 'python', text: 'Python', value: 'python' },
//   { key: 'rails', text: 'Rails', value: 'rails' },
//   { key: 'react', text: 'React', value: 'react' },
//   { key: 'repair', text: 'Kitchen Repair', value: 'repair' },
//   { key: 'ruby', text: 'Ruby', value: 'ruby' },
//   { key: 'ui', text: 'UI Design', value: 'ui' },
//   { key: 'ux', text: 'User Experience', value: 'ux' },
// ]
// const options = [
//   { key: '1111', text: 'Kiosk', value: '1111' },
//   { key: '1112', text: 'Privilage', value: '1112' }
// ]

class DropdownExampleMultipleSelection extends Component {
  state = {
    app: this.props.app
  }
  componentWillReceiveProps(nextProps) {
    this.setState({app: nextProps.app})
  }
  render() {
    const { app } = this.state
    const { option, onChangex } = this.props
    return (
      <div className='dt-inputForm-block'>
        <div className='dt-inputText'>Application</div>
        <div className=''>
          <Dropdown name='app' value={app} fluid multiple selection options={option} onChange={onChangex} />
        </div>
        <span className='dt-inputMessage'></span>
      </div>
    )
  }
}

export default DropdownExampleMultipleSelection