import { AUTHENTICATE } from '../../constants/actionType'

const authData = {
  token: '',
  role:'',
  id:'',
  isLogin: false
}

export default function authenReducer(state = authData, action) {
  switch (action.type) {
    case AUTHENTICATE.SET_DATA:
      return {
        ...state,
        token: action.payload.token,
        role: action.payload.user_role._id,
        id: action.payload._id,
        isLogin: true
      }
    case AUTHENTICATE.CLEAR_DATA:
      return {
        token: '',
        role:'',
        id:'',
        isLogin: false
      }
    default:
      return state
  }
}