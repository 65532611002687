import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { NavLink } from 'react-router-dom'

import { validate } from '../../../constants/validateform'
import { renderField } from '../../../components/input-form/renderField'
import { renderDropdown } from '../../../components/input-form/renderDropdown'
// import {renderDatePicker} from '../../../components/datepicker/renderDatePicker'
import { renderButtonDate } from '../../../components/datepicker/renderButtonDate'
import { renderFieldPassword } from '../../../components/input-form/renderFieldPassword'

let AddUserForm = props => {
  const { handleSubmit, submitting, btnDisable } = props
  return (
    <form className='changePasswordForm' onSubmit={handleSubmit}>
      <div className='dt-inputForm --oneBlock'>
        <div className='formTitle'>
          <h2>User Profile</h2>
        </div>
        <Field
          name='full_name'
          // placeholder='current password'
          type='text'
          className='dt-input'
          component={renderField}
          // icon='dt-inputField ico-key'
          validate={[validate.required]}
          label='Full Name'
        />

        <Field
          name='username'
          // placeholder='newpassword'
          type='text'
          className='dt-input'
          autoComplete='Off'
          component={renderField}
          // icon='dt-inputField ico-key'
          validate={[validate.required]}
          label='Username'
        />

        <Field
          name='roleId'
          // placeholder='confirm password'
          type='type'
          className='dt-input'
          // autoComplete='Off'
          component={renderDropdown}
          // icon='dt-inputField ico-key'
          validate={[validate.required]}
          label='Role'
        />

        {/* <Field
          name='birthdate'
          // placeholder='confirm password'
          type='date'
          className='dt-input'
          autoComplete='Off'
          component={renderField}
          // icon='dt-inputField ico-key'
          // validate={[validate.required, validate.minValue8]}
          label='Birthdate'
        /> */}

        <Field
          name='birthdate'
          // placeholder='confirm password'
          // type='date'
          autoComplete='Off'
          component={renderButtonDate}
          // icon='dt-inputField ico-key'
          // validate={[validate.required, validate.minValue8]}
          label='Birthdate'
        />

        <Field
          name='cid'
          // placeholder='confirm password'
          type='text'
          className='dt-input'
          autoComplete='Off'
          component={renderField}
          // icon='dt-inputField ico-key'
          validate={[validate.number, validate.minValue13, validate.maxLength13]}
          label='Card ID'
        />
        
        <div className='activeForm-block'>
          <Field name="active" id="active" component="input" type="checkbox" />
          <label htmlFor="active">Active</label>
        </div>

        <div className='formTitle'>
          <h2>Password</h2>
        </div>
        <Field
          name='password'
          // placeholder='newpassword'
          type='password'
          className='dt-input'
          autoComplete='Off'
          component={renderFieldPassword}
          // icon='dt-inputField ico-key'
          validate={[validate.required, validate.uppercaseLetter, validate.lowercaseLetter, validate.ditgi, validate.specialCharacter, validate.passwordMustContain]}
          label='New Password'
        />

        <Field
          name='confirmpassword'
          // placeholder='confirm password'
          type='password'
          className='dt-input'
          autoComplete='Off'
          component={renderField}
          // icon='dt-inputField ico-key'
          validate={[validate.required, validate.confirmPass]}
          label='Confirm Password'
        />

      </div>
      <div className='btnControl'>
        <div className='btnControl-center'>
          <button
            className='dt-btn --bg-success'
            type='submit'
            disabled={submitting && btnDisable}
          >
            save
        </button>
          <NavLink to={`${process.env.PUBLIC_URL}/userManagePage`} className='dt-btn'>cancel</NavLink>
        </div>
      </div>
    </form>
  )
}

AddUserForm = reduxForm({
  form: 'AddUserForm'
})(AddUserForm)

export default AddUserForm
