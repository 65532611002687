import React,{Component} from 'react'
import {connect} from 'react-redux'
import Modal from 'react-responsive-modal'

import {modalAlertAction} from '../../redux/action/modalAlert'

class Alert extends Component {
  render() {
    const { modalAlert:{open, message} } = this.props;

    return (
      <div>
        <Modal open={open} onClose={this.props.hide} closeIconSize={18} center classNames={{ modal: 'customReactResponsiveModal' }}>
          <div className='modalHead'>Message</div>
          <div className='modalContent'>
            <div className='modalContent-title'>{message}</div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  modalAlert: state.modalAlert
})

const mapDispatchToProps = dispatch => {
  return {
    hide: () => {
      dispatch(modalAlertAction.hideAlertmodal())
    }
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(Alert)