import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import PrivateRole from './route.role'
import PrivateUser from './route.user'

import siteManagePage from '../pages/site_manage'
import userManagePage from '../pages/user_manage'
import EditUserPage from '../pages/user_edit'
import AddUserPage from '../pages/user_add'
import appManagePage from '../pages/app_manage'
import AddApplicationPage from '../pages/app_add'
import EditApplicationPage from '../pages/app_edit'
import accessTokenPage from '../pages/access_token'
import userIndexPage from '../pages/user_index'

class RouteMain extends Component {
  render () {
    return (
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={siteManagePage} />
        <PrivateRole path={`${process.env.PUBLIC_URL}/userManagePage`} component={userManagePage} />
        <PrivateRole path={`${process.env.PUBLIC_URL}/EditUserPage/:id`} component={EditUserPage} />
        <PrivateRole path={`${process.env.PUBLIC_URL}/AddUserPage`} component={AddUserPage} />
        <PrivateRole path={`${process.env.PUBLIC_URL}/appManagePage`} component={appManagePage} />
        <PrivateRole path={`${process.env.PUBLIC_URL}/AddApplicationPage`} component={AddApplicationPage} />
        <PrivateRole path={`${process.env.PUBLIC_URL}/EditApplicationPage/:id`} component={EditApplicationPage} />
        <PrivateRole path={`${process.env.PUBLIC_URL}/accessTokenPage`} component={accessTokenPage} />
        <Route path={`${process.env.PUBLIC_URL}/index`} component={userIndexPage}/>
        <PrivateUser path={`${process.env.PUBLIC_URL}/UserInfoPage/:id`} component={EditUserPage} />
      </Switch>

    )
  }
}

export default RouteMain
