import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'

import configStore from './store'
import RouteRoot from './routes/route.root'
import * as serviceWorker from './serviceWorker'
import './assets/scss/main.scss'
import './assets/iconfont/material-icons.css'
import Alert from './components/modal/modalAlert'
import {NotificationContainer} from 'react-notifications'
import 'react-notifications/lib/notifications.css';

const { store, persistor } = configStore()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      loading={null}
      persistor={persistor}
    >
      <RouteRoot />
      <Alert/>
      <NotificationContainer/>
    </PersistGate>
  </Provider>
  , document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
