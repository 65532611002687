import * as type from '../../constants/actionType'

const show = (message,id) => ({
  type: type.MODALCOMFIRM.SHOW,
  payload: {message,id}
})

const hide = () => ({
  type: type.MODALCOMFIRM.HIDE
})

export const showConfirmmodal = (message,id) => async (dispatch) => {
  try {
    dispatch(show(message,id))  
  } catch (error) {
    console.loh('ACTION_SHOWALERTMODAL', error)
  }
}

export const hideConfirmmodal = () => async (dispatch) =>{
  try {
    dispatch(hide())  
  } catch (error) {
    console.loh('ACTION_HIDEALERTMODAL', error)
  }
}

export const modalConfirmAction = {
  showConfirmmodal,
  hideConfirmmodal
}