import React, { Component } from 'react'
import { connect } from 'react-redux'

import { appAction } from '../../redux/action/applications'
import { CircleLoading } from '../../components/loading/circleloading'

import EditAppForm from './form'

class EditApplicationPage extends Component {
  componentWillMount(){
    this.props.restApp()
  }
  componentDidMount() {
    const { match: { params } } = this.props
    this.props.getAppById(params.id)
  }
  submit = value => {
    // console.log(value)
    const { match: { params } } = this.props
    const data = {
      code: value.code,
      name: value.name,
      url: value.url,
      active: value.active ? value.active : false
    }
    // console.log(data)
    this.props.editApp(params.id,data)
    this.props.history.push(`${process.env.PUBLIC_URL}/appManagePage`)
  }
  render() {
    const { app } = this.props
    if (Object.keys(app).length === 0) {
      return <CircleLoading/>
    } else {
      return (
        <div>
          <div className="showInfoHead">
            <div className="showInfoHead-title">Edit Application</div>
            <div className="showInfoHead-contentRight" />
          </div>
          <div className="showInfoContent">
            <EditAppForm onSubmit={this.submit}/>
          </div>
        </div>
      )
    }
  }
}


const mapStateToProps = state => ({
  app: state.application
})

const mapDispatchToProps = dispatch => {
  return {
    getAppById: (appId) => {
      dispatch(appAction.getApplicationById(appId))
    },
    editApp: (appId,data) => {
      dispatch(appAction.editApplication(appId,data))
    },
    restApp: () => {
      dispatch(appAction.restApp())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditApplicationPage)
