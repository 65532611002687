import React from 'react'

import TableRow from './table-row'

const TableTbody = ({ apps }) => {
  return (
    <tbody>
      {
        apps.map((app, i) => {
          return (
            <TableRow
              key={i}
              id={app._id}
              code={app.code}
              name={app.name}
              url={app.url}
              active={app.active}
          />
          )
        })
      }
    </tbody>
  )
}

export default TableTbody
