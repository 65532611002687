import * as type from '../../constants/actionType'

const clearToken = () => ({
  type: type.AUTHENTICATE.CLEAR_DATA
})

const userLogout = () => ({
  type: type.LOGOUT.LOGOUT
})

export const logout = () => async (dispatch) => {
  try {
    // await localStorage.clear()
    dispatch(userLogout())  
    dispatch(clearToken())
    // 

  } catch (error) {
    console.loh('ACTION_LOGOUT', error)
  }
}

export const logoutAction = {
  logout
}