import { USERS } from '../../constants/actionType'

const users = {
  data: [],
  isLoading: false
}

export default function usersReducer(state = users, action) {
  switch (action.type) {
    case USERS.GET_DATA:
      return {
        ...state,
        data: action.payload,
        isLoading: false
      }
    case USERS.LOAD_DATA:
      return {
        ...state,
        data: [],
        isLoading: true
      }
    default:
      return state
  }
}
