import * as type from '../../constants/actionType'

const show = (message) => ({
  type: type.MODALALERT.SHOW,
  payload: message
})

const hide = () => ({
  type: type.MODALALERT.HIDE
})

export const showAlertmodal = (message) => async (dispatch) => {
  try {
    dispatch(show(message))  
  } catch (error) {
    console.loh('ACTION_SHOWALERTMODAL', error)
  }
}

export const hideAlertmodal = () => async (dispatch) =>{
  try {
    dispatch(hide())  
  } catch (error) {
    console.loh('ACTION_HIDEALERTMODAL', error)
  }
}

export const modalAlertAction = {
  showAlertmodal,
  hideAlertmodal
}