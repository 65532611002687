import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import AddUserForm from './form'
import { usersAction } from '../../redux/action/users'
import { modalAlertAction } from '../../redux/action/modalAlert'
import {capitalFirstLetter} from '../../constants/textFunc'

class AddUserPage extends Component {
  submit = value => {
    
    if(value.password !== value.confirmpassword){
      // alert('confirmpassword not match password')
      this.props.showAlert(capitalFirstLetter('confirmpassword not match password'))
      return false
    } 
    const data = {
      full_name: value.full_name,
      username: value.username,
      password: value.password,
      active: value.active ? value.active : false,
      roleId: parseInt(value.roleId),
      // birthdate: value.birthdate,
      birthdate: String(moment(value.birthdate).format('YYYY-MM-DD')),
      cid: value.cid
    }
    // console.log(data)
    this.props.addUser(data)
    this.props.history.push(`${process.env.PUBLIC_URL}/userManagePage`)
  }
  render() {
   
    return (
      <div>
        <div className="showInfoHead">
          <div className="showInfoHead-title">Add User</div>
          <div className="showInfoHead-contentRight" />
        </div>
        <div className="showInfoContent">
          <AddUserForm onSubmit={this.submit} />
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addUser: (data) => {
      dispatch(usersAction.addUser(data))
    },
    showAlert: (message) => {
      dispatch(modalAlertAction.showAlertmodal(message))
    }
  }
}


export default connect(null, mapDispatchToProps)(AddUserPage)
