import * as type from '../../constants/actionType'
import { Service } from '../../service'
import { modalAlertAction } from './modalAlert'
import { logoutAction } from './logout'
import { NotificationManager } from 'react-notifications'
import {capitalFirstLetter} from '../../constants/textFunc'

const getApps = (data) => ({
  type: type.APPLICATIONS.GET_DATA,
  payload: data
})

const loadApps = () => ({
  type: type.APPLICATIONS.LOAD_DATA
})

const getOneApp = (data) => ({
  type: type.APPLICATION.GET_DATA,
  payload: data
})

const clearOneApp = () => ({
  type: type.APPLICATION.CLEAR_DATA,
})

export const addApplication = (data) => async (dispatch) => {
  try {
    const response = await Service.Applications.addApplication(data)
    if (response.status === 200) {
      dispatch(appAction.getAllApplication())
      // dispatch(modalAlertAction.showAlertmodal('success'))
      NotificationManager.success('Successful', 'Add', 3000)
    } else if (response.status === 401) {
      dispatch(logoutAction.logout())
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter('Please login')))
    } else if (response.status === 422) {
      NotificationManager.warning('Fail', 'Add', 3000);
    } else {
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter(response.data.errors.message)))
    }
  } catch (err) {
    console.log('ACTION_ADDAPPLICTION', err)
    dispatch(modalAlertAction.showAlertmodal('Internal server error'))
  }
}

export const getAllApplication = () => async (dispatch) => {
  try {
    const response = await Service.Applications.getAllApplication()
    if (response.status === 200) {
      dispatch(loadApps())
      dispatch(getApps(response.data))
    } else if (response.status === 401) {
      dispatch(logoutAction.logout())
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter('Please login')))
    } else {
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter(response.data.errors.message)))
    }
  } catch (err) {
    console.log('ACTION_GETALLAPPS', err)
    dispatch(modalAlertAction.showAlertmodal('Internal server error'))
  }
}

export const getApplicationById = (appId) => async (dispatch) => {
  try {
    const response = await Service.Applications.getApplicationById(appId)
    if (response.status === 200) {
      dispatch(getOneApp(response.data[0]))
    } else if (response.status === 401) {
      dispatch(logoutAction.logout())
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter('Please login')))
    } else {
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter(response.data.errors.message)))
    }
  } catch (err) {
    console.log('ACTION_GETAPPBYID', err)
    dispatch(modalAlertAction.showAlertmodal('Internal server error'))
  }
}

export const editApplication = (appId, data) => async (dispatch) => {
  try {
    const response = await Service.Applications.editApplication(appId, data)
    if (response.status === 200) {
      dispatch(appAction.getAllApplication())
      // dispatch(modalAlertAction.showAlertmodal('Success'))
      NotificationManager.success('Successful', 'Edit', 3000)
      dispatch(clearOneApp())
    } else if (response.status === 401) {
      dispatch(logoutAction.logout())
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter('Please login')))
    } else if (response.status === 422) {
      NotificationManager.warning('Fail', 'Edit', 3000);
    }else {
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter(response.data.errors.message)))
    }
  } catch (err) {
    console.log('ACTION_EDITAPPLICATIOn', err)
    dispatch(modalAlertAction.showAlertmodal('Internal server error'))
  }
}

export const restApp = () => async (dispatch) => {
  try {
    dispatch(clearOneApp())
  } catch (err) {
    console.log('ACTION_RESETAPPLICATIOn', err)
  }
}


export const appAction = {
  addApplication,
  getAllApplication,
  getApplicationById,
  editApplication,
  restApp
}
