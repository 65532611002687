import React from 'react'

const SearchBox = ({searchChange}) => {
  return (
    <div className='showInfo-head-contentRight'>
      <div className='dt-inputField ico-search'>
        <input name='search' placeholder='Search' type='text' className='dt-input' onChange={searchChange} />
      </div>
    </div>
  )
}

export default SearchBox
