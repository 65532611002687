import * as type from '../../constants/actionType'
import {Service} from '../../service'
import {modalAlertAction} from './modalAlert'
import {capitalFirstLetter} from '../../constants/textFunc'

const setAuth = (data) => ({
  type: type.AUTHENTICATE.SET_DATA,
  payload: data
})


export const authenticate = (data) => async (dispatch) => {
  try{
    const response = await Service.Authenticate.authenticate(data)
    if(response.status === 200){
      if(response.data.error){
        dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter(response.data.error.message)))
      }else{
        dispatch(setAuth(response.data))
      }
    }else if(response.status === 400){
      dispatch(modalAlertAction.showAlertmodal(capitalFirstLetter(response.data.error.message)))
    }else{
      dispatch(modalAlertAction.showAlertmodal('Username or Password incorrect'))
    }
  }catch(err){
    console.log('ACTION_AUTH',err)
    dispatch(modalAlertAction.showAlertmodal('Error connect server'))
  }
}

export const authenAction = {
  authenticate
}

