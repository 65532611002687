import { ACCESSTOKEN } from '../../constants/actionType'

const accessToken = {
  data: [],
  isLoading: false
}

export default function usersReducer(state = accessToken, action) {
  switch (action.type) {
    case ACCESSTOKEN.GET_DATA:
      return {
        ...state,
        data: action.payload,
        isLoading: false
      }
    case ACCESSTOKEN.LOAD_DATA:
      return {
        ...state,
        data: [],
        isLoading: true
      }
    default:
      return state
  }
}