import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { validate } from '../../../constants/validateform'
import { renderField } from '../../../components/input-form/renderField'
import DropdownExampleMultipleSelection from '../../../components/semantic/dropdownField'

let AccessTokenForm = props => {
  const { handleSubmit, submitting, btnDisable, option, onChangex,app } = props
  return (
    <form className='dt-inputForm --oneBlock nhsoTokenForm' onSubmit={handleSubmit}>
      <Field name='tokenName' type='text' component={renderField} validate={[validate.required]} label='Access Token Name' />
      <DropdownExampleMultipleSelection option={option} onChangex={onChangex} app={app}/>
      <div className='btnAction'>
        <button className='dt-btn --iconOnly --bg-success' type='submit' disabled={submitting && btnDisable}><i className="material-icons">add</i></button>
      </div>
    </form>
  )
}

AccessTokenForm = reduxForm({
  form: 'AccessTokenForm'
})(AccessTokenForm)

export default AccessTokenForm
