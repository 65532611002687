import React from 'react'

import TableRow from './table-row'

const TableTbody = ({ accessTokens }) => {
  return (
    <tbody>
      {
        accessTokens.map((accesstoken, i) => {
          return (
            <TableRow
              key={i}
              id={accesstoken._id}
              name={accesstoken.name}
              token={accesstoken.token}
              app={accesstoken.application}
          />
          )
        })
      }
    </tbody>
  )
}

export default TableTbody
