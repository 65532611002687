import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { reducer as reduxFormReducer } from 'redux-form'
import * as type from '../../constants/actionType'

import authen from './authenticate'
import users from './users'
import user from './user'
import hospital from './hospital'
import application from './application'
import applications from './applications'
import accessToken from './accesstoken'
import modalAlert from './modalAlert'
import modalConfirm from './modalConfirm'

const barPersistConfig = {
  key: 'userLogin',
  storage,
  keyPrefix: '_'
}

const appReducer = combineReducers({
  form: reduxFormReducer,
  authen: persistReducer(barPersistConfig, authen),
  users,
  user,
  hospital,
  application,
  applications,
  accessToken,
  modalAlert,
  modalConfirm

})

const rootReducer = (state, action) => {
  if(action.type === type.LOGOUT.LOGOUT) {
    state.form = undefined
    state.users = undefined
    state.user = undefined
    state.hospital = undefined
    state.application = undefined
    state.applications = undefined
    state.accessToken = undefined
    state.modalAlert = undefined
    state.modalConfirm = undefined
  }
  return appReducer(state, action)
}

export default rootReducer