import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { validate } from '../../../constants/validateform'
import { renderField } from '../../../components/input-form/renderField'
import { renderUrlField } from '../../../components/input-form/renderUrlField'

let EditAppForm = props => {
  const { handleSubmit, submitting, btnDisable } = props
  return (
    <form className='changePasswordForm' onSubmit={handleSubmit}>
      <div className='dt-inputForm --oneBlock'>
        <div className='formTitle'>
          <h2>Application</h2>
        </div>
        <Field
          name='code'
          // placeholder='current password'
          type='text'
          className='dt-input'
          component={renderField}
          // icon='dt-inputField ico-key'
          validate={[validate.required]}
          label='Code'
        />

        <Field
          name='name'
          // placeholder='newpassword'
          type='text'
          className='dt-input'
          autoComplete='Off'
          component={renderField}
          // icon='dt-inputField ico-key'
          validate={[validate.required]}
          label='Application'
        />
        <Field
          name='url'
          // placeholder='newpassword'
          type='text'
          className='dt-input'
          autoComplete='Off'
          component={renderUrlField}
          // icon='dt-inputField ico-key'
          validate={[validate.required]}
          label='URL'
        />
        <div className='activeForm-block'>
          <Field name="active" id="active" component="input" type="checkbox" />
          <label htmlFor="active">Active</label>
        </div>

      </div>
      <div className='btnControl'>
        <div className='btnControl-center'>
          <button
            className='dt-btn --bg-success'
            type='submit'
            disabled={submitting && btnDisable}
          >
            save
        </button>
          <NavLink to={`${process.env.PUBLIC_URL}/appManagePage`} className='dt-btn'>cancel</NavLink>
        </div>
      </div>
    </form>
  )
}

const mapStateToProps = state => {
  return {
    initialValues: {
      code: state.application.code,
      name: state.application.name,
      // url: (state.application.url).replace(`${window.location.origin}/`, ''),
      url: state.application.url,
      active: state.application.active
    }
  }
}

EditAppForm = reduxForm({
  form: 'EditAppForm'
})(EditAppForm)

export default connect(mapStateToProps)(EditAppForm)
