import { APPLICATIONS } from '../../constants/actionType'

const applications = {
  data: [],
  isLoading: false
}

export default function applicationsReducer(state = applications, action) {
  switch (action.type) {
    case APPLICATIONS.GET_DATA:
      return {
        ...state,
        data: action.payload,
        isLoading: false
      }
    case APPLICATIONS.LOAD_DATA:
      return {
        ...state,
        data: [],
        isLoading: true
      }
    default:
      return state
  }
}
