import React from 'react'
import ReactLoading from 'react-loading'

export const CircleLoading = () => (
  <div className='loading-box'>
    <div className='loading'>
      <ReactLoading type='spinningBubbles' color='#3a3a3a' height={20} width={20} />
      <span>Loading</span>
    </div>
  </div>
)