import React from 'react'

import TableRow from './table-row'

const TableTbody = ({ users, changeRole, deleteUser }) => {
  return (
    <tbody>
      {
        users && users.map((user, i) => {
          return (
            <TableRow
              key={i}
              id={`${user._id}`}
              name={`${user.full_name}`}
              username={user.username}
              userrole={user.user_role}
              active={user.active}
          />
          )
        })
      }
    </tbody>
  )
}

export default TableTbody
