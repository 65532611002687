import React from 'react'
import {connect} from 'react-redux'
import { logoutAction } from '../../redux/action/logout'

const Header = ({ logout }) => {
  return (
    <div data-include='mainHead' className='mainHeader'>
      <div className='mainHeader-logo'>
      <a href={'/'}>Smart Hospital Web Admin</a>
      </div>
      <div className='mainHeader-content' />
      <div className='mainHeader-logout'>
        <button className='btnLogout ico-exit' onClick={logout}>Logout</button>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logoutAction.logout())
    }
  }
}

export default connect(null,mapDispatchToProps)(Header)
