import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import { fn } from '../../shared/fn'


class Navbar extends Component {
  render() {
    const { role, activeApps, userId } = this.props

    return (
      <div id='mainContent-navBar' data-include='mainNavBar' className='mainContent-navBar mainContent-navBar-fix'>
        <div className='mainMenuHead'>
          <i className='ico-menu' onClick={() => fn.ToggleClass('mainContent', '--MenuExpand')} />
          <span>menu</span>
        </div>
        <>
          {
            (role && role) === 1 &&
            <>
              <div className='mainMenuList-title'>
                <span>Setting</span>
              </div>
              <ul className='mainMenuList mainMenuList-fix'>
                <NavLink exact to={`${process.env.PUBLIC_URL}/`} className='mainMenuList-item' activeClassName='--active' onClick={() => fn.DeleteClass('mainContent', '--MenuExpand')} >
                <i className="material-icons">info</i>
                  <span>Information</span>
                </NavLink>
                <NavLink to={`${process.env.PUBLIC_URL}/userManagePage`} className='mainMenuList-item' activeClassName='--active' onClick={() => fn.DeleteClass('mainContent', '--MenuExpand')}>
                <i className="material-icons">supervisor_account</i>
                  <span>User Management</span>
                </NavLink>
                <NavLink to={`${process.env.PUBLIC_URL}/appManagePage`} className='mainMenuList-item' activeClassName='--active' onClick={() => fn.DeleteClass('mainContent', '--MenuExpand')}>
                <i className="material-icons">apps</i>
                  <span>Application Management</span>
                </NavLink>
                <NavLink to={`${process.env.PUBLIC_URL}/accessTokenPage`} className='mainMenuList-item' activeClassName='--active' onClick={() => fn.DeleteClass('mainContent', '--MenuExpand')}>
                  <i className='ico-swapHorizCircle' />
                  <span>Access Token</span>
                </NavLink>
              </ul>
            </>
          }
          {
            (role && role) === 2 &&
            <>
            <div className='mainMenuList-title'>
                <span>Setting</span>
              </div>
              <ul className='mainMenuList mainMenuList-fix'>
                <NavLink exact to={`${process.env.PUBLIC_URL}/`} className='mainMenuList-item' activeClassName='--active' onClick={() => fn.DeleteClass('mainContent', '--MenuExpand')} >
                <i className="material-icons">info</i>
                  <span>Infomation</span>
                </NavLink>
                <NavLink to={`${process.env.PUBLIC_URL}/UserInfoPage/${userId}`} className='mainMenuList-item' activeClassName='--active' onClick={() => fn.DeleteClass('mainContent', '--MenuExpand')}>
                <i className="material-icons">account_box</i>
                  <span>User Infomation</span>
                </NavLink>
              </ul>
            </>
          }
        </>
        <div className='mainMenuList-title'>
          <span>Application</span>
        </div>
        <ul className='mainMenuList mainMenuList-fix'>
          {
            activeApps && activeApps.map((link, key) => {
              return (
                <a key={key} href={`${window.location.origin}/${link.url}`} className='mainMenuList-item' onClick={() => fn.DeleteClass('mainContent', '--MenuExpand')} >
                  <i className="material-icons">web_asset</i>
                  <span>{link.name}</span>
                </a>
              )
            })
          }

        </ul>

        <div className='applicationContent-bar'><p>{`Smart Hospital Web Admin  v${process.env.APP_VERSION}`}</p><p>{`Open Source Technology Co., Ltd.`}</p></div>
      </div>
    )
  }
}




export default Navbar
