import React,{Component} from 'react'
import 'semantic-ui-css/semantic.min.css'
import { Icon, Label } from 'semantic-ui-react'


class LabelExampleIcon extends Component{
  state = {
    id: this.props.allDataInRow.id,
    name: this.props.allDataInRow.name,
    application: [],
    active: true
  }
  render(){
  const { tagName } = this.props
  return (
    <Label as='a'>
      {tagName}
      <Icon name='delete' />
    </Label>
  )
  }
}

export default LabelExampleIcon