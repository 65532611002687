import { call } from './setting'

export default {
  getAllUser: async () => {
    try {
      return await call().then((send) => send.get('/users/'))
        .catch((err) => {
          return err.response
        })

    } catch (err) {
      console.log('GETALLUSER_SERVICE', err)
    }
  },
  getUserById: async (userId) => {
    try {
      return await call().then((send) => send.get(`/users/${userId}`))
        .catch((err) => {
          return err.response
        })

    } catch (err) {
      console.log('GETUSERBYID_SERVICE', err)
    }
  },
  addUser: async (data) => {
    try {
      return await call().then((send) => send.post('/users/',data))
        .catch((err) => {
          return err.response
        })

    } catch (err) {
      console.log('ADDUSER_SERVICE', err)
    }
  },
  editUser: async (userId,data) => {
    try {
      return await call().then((send) => send.put(`/users/${userId}`,data))
        .catch((err) => {
          return err.response
        })

    } catch (err) {
      console.log('EDITUSER_SERVICE', err)
    }
  }
}
